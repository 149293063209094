import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import {changeItemOrder, changeStreamList} from "./schedulerActions";
import {sortBy} from "lodash";
import {getWeekDates} from "../../helper/dayHelper";

const initialState = {
  constants: {
    hourHeight: 120,
    durationHeight: 120 / 3600, //1 sec height
    hours: 12,//hours in day (PM, AM)
    orderRate: 1000
  },
  items: [],
  baseItems: []
};

const getFormatItems = (streamList, weekDates) => {
  let items = [];
  for(let key in streamList ) {
    let stream = streamList[key];
    if(stream.showDates.length >= 1) {
      for(let i in stream.showDates) {
        let date = stream.showDates[i];
        let findDate = weekDates.find(day => day.date === date);
        if(findDate) {
          items.push({
            day: date,
            items: stream.items
          })
        }
      }
    }
  }
  return items;
}

export const getDayInfo = (items, constants) => {
  let days = structuredClone(items);
  for (let i in days) {
    days[i].items = sortBy(days[i].items, ['order']);
    let cards = structuredClone(days[i].items);
    let timeStart = 0;
    for(let key in cards) {
      cards[key].timeStart = {
        start: timeStart,
        interval: timeStart / (constants.hours * 3600)  >= 1 ? 'PM' : 'AM',
        timeFromInterval: timeStart >= (constants.hours * 3600) ? timeStart - constants.hours * 3600 : timeStart
      };

      cards[key].position = cards[key].timeStart.timeFromInterval * constants.durationHeight;
      cards[key].position += cards[key].timeStart.timeFromInterval !== 0 ? parseInt(key) : 0;

      cards[key].height = constants.durationHeight * (cards[key].durationWithAds || cards[key].duration);

      if(!cards[key]?.text) {
        cards[key].text = cards[key]?.name || cards[key]?.originalName || cards[key]?.fileName || null
      }

      if(cards[key].order < constants.orderRate) {
        cards[key].order = ( cards[key].order + 1 ) * constants.orderRate;
      }
      timeStart += (cards[key].durationWithAds || cards[key].duration);

    }
    days[i].items = cards;
  }

  return days;
}

export const schedulerReducer = createReducer(initialState, (builder) => {
  builder
      .addCase(changeStreamList, (state, action) => {
        let {streamList, timeOffset} = action.payload;
        let weekDates = getWeekDates(timeOffset);
        state.baseItems = getFormatItems(streamList, weekDates);
        // state.items = getDayInfo(formatItems, state.constants);
      })
});
